import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../layouts'
import TagsPage from './tags-page';

class TagRoute extends React.Component {
        constructor(props){
          super(props);
          this.state={}
        }
  render() {
   
    const pts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const posts = pts.filter(el=>el.node.frontmatter.design_tags && el.node.frontmatter.design_tags.trim().split(/\s*,\s*/).includes(tag))


   
    return (
      <TagsPage name={this.props.pageContext.tag} next={this.props.pageContext.nextTag} prev={this.props.pageContext.prevTag} data={posts} location={this.props.location}/>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage{
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "design-detail"}}})  {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            design_tags
            description
            featuredimage {
              publicURL
              childImageSharp{
                      fluid{
                        srcSet
                        src
                      }
                    }
          }
          image_1 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_2 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_3 {
            publicURL
            childImageSharp{
                    fluid{
                      src
                      srcSet
                    }
                  }
        }
          image_4 {
            publicURL
            childImageSharp{
                    fluid{
                      src
                      srcSet
                    }
                  }
        }
          image_5 {
            publicURL
            childImageSharp{
                    fluid{
                      src
                      srcSet
                    }
                  }
        }
          image_6 {
            publicURL
            childImageSharp{
                    fluid{
                      src
                      srcSet
                    }
                  }
        }
          image_7 {
            publicURL
            childImageSharp{
                    fluid{
                      src
                      srcSet
                    }
                  }
        }
            image_8 {
                publicURL             
                childImageSharp{
                        fluid{
                          src                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
                          srcSet
                        }
                      }
            }
          }
        }
      }
    }
  }
`
