import React,{useState,useEffect} from 'react'
import _ from 'lodash'
import Layout from '../layouts'
import GridView from '../components/assets/images/grid-view'
import ListView from '../components/assets/images/list-view'
import DesignsViewSection from '../components/designsview'
import Helmet from 'react-helmet'
import { navigate, Link } from 'gatsby'
import { isMobile } from 'react-device-detect'
export const TagsPageTemplate = ({name, data,location,next,prev}) => {
  const windowGlobal = typeof window !== 'undefined' && window
  const [actvesec,setActivesec] = useState(location.pathname)
  const setCaseviewfunction = () =>{

    if(windowGlobal){
      return windowGlobal.localStorage.getItem('caseview') ? parseInt(windowGlobal.localStorage.getItem('caseview')) : 0
    }else{
      return 0
    }
    
  }
  const [tabview, setTabview] = useState(setCaseviewfunction)
  const scrollLisFunction = () =>{
    if(window){
      if(window.scrollY !==0){
        sessionStorage.setItem(`case_list_y`,window.scrollY)
      }    
    }
  
  }
  useEffect(()=>{
    if(window !== undefined){
      window.addEventListener('scroll',scrollLisFunction)      
    }
    return ()=>{
      window.removeEventListener('scroll',scrollLisFunction)
    }
  },[tabview])

 
  const handleTabView=(de)=>{
    if(window !== undefined){
      localStorage.setItem('caseview',de)
    }
    setTabview(de)
  }
  const casesList = data
  return (
    <React.Fragment>
      <Helmet defer={false}>
        <title>Tags | JDSolutions</title> 
         <meta property="og:title" content={`Categories | JDSolutions`} />
        <meta property="og:type" content="cases" />
        {/* <meta property="og:image" content="http://ia.media-imdb.com/images/rock.jpg" />      */}
      </Helmet>
    <div className="row-fluid-wrapper row-depth-1 row-number-1 nvm">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell case-page-filter" style={{paddingTop:'144px'}}>
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell wrapper">
      <div className="row-fluid-wrapper row-depth-1 row-number-3">
      <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_html list-filter-type">


        <div className="row-fluid-wrapper row-depth-1 row-number-4 cu85">
          <div className="row-fluid ">
            <div className="span12 widget-span widget-type-raw_jinja " 
            style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
              <div className="next-prev-case" >
                <Link className="back-list en-text" to="/designs" >
                  <span>Back to Designs</span>
                  </Link>
                
                
                      </div>
                     {!isMobile ? <div className="desktop" style={{textTransform:'capitalize',fontSize:'14px',fontWeight:300,display:'flex',alignItems:'center'}}>
                       {name} 
                      </div>:
                      <div className="mobile trunk" style={{textTransform:'capitalize',fontSize:'14px',fontWeight:300,display:'flex',alignItems:'center'}}>
                       {name} 
                      </div>}
                      <div className="next-prev-case" style={{width:'auto'}}>
                        <a className={`next-list en-text ${prev ? '' : `disable`}`} href="javaScript:void(0)" 
                        onClick={()=>navigate(`/designs/tags/${_.kebabCase(prev)}/`)}
                        style={{marginRight:'15px'}}>
                          <span style={{whiteSpace:'nowrap'}}>Previous tag</span>
                          </a>
                          <a className={`prev-list en-text ${next ? '' : `disable`}`} 
                           onClick={()=>navigate(`/designs/tags/${_.kebabCase(next)}/`)}
                          href="javaScript:void(0)">
                            <span style={{whiteSpace:'nowrap'}}>Next tag</span>
                            </a>
                            </div>
                            </div>
                            </div>
                            </div>


          <div className="cell-wrapper layout-widget-wrapper">
            <div className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
              <ul>
                <li className={`case-list-type ${tabview === 1 ? 'active-list':'inactive'}`}
                onClick={()=>handleTabView(1)}
                >
                  <ListView/>
                </li>
                <li className={`grid-list-type ${tabview === 0 ? 'active-list':'inactive'}`}
                 onClick={()=>handleTabView(0)}
                >
                  <GridView/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    </div>
      </div>
      </div>
      </div>
      </div>
      <div className="row-fluid-wrapper row-depth-1 row-number-4">
      <div className="row-fluid ">
      <div className={`span12 widget-span widget-type-cell cases-lists ${tabview === 1 ? 'list' : ''}`}>
      <div className="row-fluid-wrapper row-depth-1 row-number-5">
        <div className="row-fluid ">
          <div className="span12 widget-span widget-type-cell wrapper">
            <div className="row-fluid-wrapper row-depth-1 row-number-6">
              <div className="row-fluid ">
                <div className="span12 widget-span widget-type-widget_container cases-lists-cm">
                  <span className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container">
                    {casesList.map((dt,i)=>{
                      return <DesignsViewSection data={dt} kk={i}/>
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
        </div>
      </div>
      </React.Fragment>
  )
}


const TagsPage = ({name, data,location,next,prev}) => {
  const [page, setPage] = useState('design case-detail formal')
  return (
    <Layout page={page}>
      <TagsPageTemplate name={name} data={data} next={next} prev={prev} location={location}/>
    </Layout>
  )
}



export default TagsPage

